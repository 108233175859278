<template>
  <div />
</template>

<script>
export default {
  mounted() {
    var authData = {
      token: this.$route.params.token
    };
    this.$store.dispatch("auth/login", authData);
    this.$router.push({ name: "Index.Page" });
  }
};
</script>
